<template>
    <BlockContainer
        :background-color="data.backgroundColor"
        :bottom-angle="data.bottomAngle"
        :angle-background-color="data.nextBackgroundColor"
        :arrow-callout="data.arrowCallout"
    >
        <div class="grid gap-x-16 py-14 md:grid-cols-2 lg:py-28">
            <div class="flex flex-col justify-end">
                <h2 v-if="data.heading" class="pb-4">
                    {{ data.heading }}
                </h2>
                <WysiwygContent :content="data.description" class="mb-4" />
            </div>
            <div class="order-last">
                <div v-if="data.buttons" class="gap-x-2 md:flex">
                    <Button
                        v-for="(button, key) in data.buttons"
                        :key="key"
                        :button="button"
                        class="w-full md:w-auto"
                    />
                </div>
            </div>
            <div
                v-if="data.secondaryImage.src"
                class="relative min-h-[240px] items-center justify-center py-6 md:row-span-2 md:row-start-1 lg:min-h-[400px]"
                :class="[
                    data.reverseLayout ? 'md:col-start-1' : 'md:col-start-2',
                ]"
                @mouseenter="addAnimation()"
                @mouseleave="removeAnimation()"
                @click="addAnimation()"
            >
                <img
                    v-if="data.image"
                    :src="data.image.src"
                    :alt="data.image.alt"
                    class="absolute rounded-lg"
                    :class="{
                        hidden: onHover,
                    }"
                />
                <img
                    v-if="data.secondaryImage"
                    :src="data.secondaryImage.src"
                    :alt="data.secondaryImage.alt"
                    class="absolute rounded-lg"
                    :class="{
                        hidden: !onHover,
                    }"
                />
            </div>
            <div
                v-if="data.calloutImage1.src"
                class="relative min-h-[240px] items-center justify-center py-6 md:row-span-2 md:row-start-1 lg:min-h-[400px]"
                :class="[
                    data.reverseLayout ? 'md:col-start-1' : 'md:col-start-2',
                ]"
                @mouseenter="addAnimation()"
                @mouseleave="removeAnimation()"
                @click="toggleAnimation()"
            >
                <img
                    v-if="data.calloutImage1.src"
                    :src="data.calloutImage1.src"
                    :alt="data.calloutImage1.alt"
                    class="absolute left-0 top-0 translate-x-[-25%] scale-[60%] rounded-lg xl:translate-x-0 xl:scale-[80%] 2xl:scale-100"
                    :class="[onHover ? 'shrink-callout-1' : 'grow-callout-1']"
                />
                <img
                    v-if="data.calloutImage2.src"
                    :src="data.calloutImage2.src"
                    :alt="data.calloutImage2.alt"
                    class="absolute right-0 top-0 translate-x-[25%] scale-[60%] rounded-lg xl:translate-x-0 xl:scale-[80%] 2xl:scale-100"
                    :class="[onHover ? 'shrink-callout-2' : 'grow-callout-2']"
                />
                <img
                    v-if="data.calloutImage3.src"
                    :src="data.calloutImage3.src"
                    :alt="data.calloutImage3.alt"
                    class="absolute bottom-0 right-0 translate-x-[25%] translate-y-[-25%] scale-[60%] rounded-lg xl:translate-x-0 xl:translate-y-0 xl:scale-[80%] 2xl:scale-100"
                    :class="[onHover ? 'shrink-callout-3' : 'grow-callout-3']"
                />
                <img
                    v-if="data.calloutImage4.src"
                    :src="data.calloutImage4.src"
                    :alt="data.calloutImage4.alt"
                    class="absolute bottom-0 left-0 translate-x-[-25%] translate-y-[-25%] scale-[60%] rounded-lg xl:translate-x-0 xl:translate-y-0 xl:scale-[80%] 2xl:scale-100"
                    :class="[onHover ? 'shrink-callout-4' : 'grow-callout-4']"
                />
                <img
                    v-if="data.image"
                    :src="data.image.src"
                    :alt="data.image.alt"
                    class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] scale-[50%] rounded-lg lg:scale-[80%] 2xl:scale-100"
                />
            </div>
        </div>
    </BlockContainer>
</template>

<script setup lang="ts">
import AnimatedImageContentType from '~/types/AnimatedImageContentType';
import Button from '~/components/page-building/components/Button.vue';
import BlockContainer from '~/components/BlockContainer.vue';

defineProps<{
    data: AnimatedImageContentType;
}>();

const onHover = ref(false);

const addAnimation = () => {
    onHover.value = true;
};

const removeAnimation = () => {
    onHover.value = false;
};

const toggleAnimation = () => {
    onHover.value = !onHover.value;
};
</script>

<style scoped>
.grow-callout-1 {
    opacity: 1;
    top: 0;
    left: 0;
    animation: grow-callout-1 0.4s ease;
}

.shrink-callout-1 {
    opacity: 0;
    top: 25%;
    left: 25%;
    animation: shrink-callout-1 0.4s ease;
}

.grow-callout-2 {
    opacity: 1;
    top: 0;
    right: 0;
    animation: grow-callout-2 0.4s ease;
}

.shrink-callout-2 {
    opacity: 0;
    top: 25%;
    right: 25%;
    animation: shrink-callout-2 0.4s ease;
}

.grow-callout-3 {
    opacity: 1;
    bottom: 0;
    right: 0;
    animation: grow-callout-3 0.4s ease;
}

.shrink-callout-3 {
    opacity: 0;
    bottom: 25%;
    right: 25%;
    animation: shrink-callout-3 0.4s ease;
}

.grow-callout-4 {
    opacity: 1;
    bottom: 0;
    left: 0;
    animation: grow-callout-4 0.4s ease;
}

.shrink-callout-4 {
    opacity: 0;
    bottom: 25%;
    left: 25%;
    animation: shrink-callout-4 0.4s ease;
}

@keyframes grow-callout-1 {
    from {
        opacity: 0;
        top: 25%;
        left: 25%;
    }

    to {
        opacity: 1;
        top: 0;
        left: 0;
    }
}

@keyframes shrink-callout-1 {
    from {
        opacity: 1;
        top: 0;
        left: 0;
    }

    to {
        opacity: 0;
        top: 25%;
        left: 25%;
    }
}

@keyframes grow-callout-2 {
    from {
        opacity: 0;
        top: 25%;
        right: 25%;
    }

    to {
        opacity: 1;
        top: 0;
        right: 0;
    }
}

@keyframes shrink-callout-2 {
    from {
        opacity: 1;
        top: 0;
        right: 0;
    }

    to {
        opacity: 0;
        top: 25%;
        right: 25%;
    }
}

@keyframes grow-callout-3 {
    from {
        opacity: 0;
        bottom: 25%;
        right: 25%;
    }

    to {
        opacity: 1;
        bottom: 0;
        right: 0;
    }
}

@keyframes shrink-callout-3 {
    from {
        opacity: 1;
        bottom: 0;
        right: 0;
    }

    to {
        opacity: 0;
        bottom: 25%;
        right: 25%;
    }
}

@keyframes grow-callout-4 {
    from {
        opacity: 0;
        bottom: 25%;
        left: 25%;
    }

    to {
        opacity: 1;
        bottom: 0;
        left: 0;
    }
}

@keyframes shrink-callout-4 {
    from {
        opacity: 1;
        bottom: 0;
        left: 0;
    }

    to {
        opacity: 0;
        bottom: 25%;
        left: 25%;
    }
}
</style>
