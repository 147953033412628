<template>
    <AnimatedImageContent v-editable="blok" :data="animatedImageContent" />
</template>

<script setup lang="ts">
import AnimatedImageContentType from '@/types/AnimatedImageContentType';
import ButtonType from '@/types/ButtonType';
import ImageType from '@/types/ImageType';
import AnimatedImageContent from '~/components/page-building/AnimatedImageContent.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const buttons = props.blok.buttons
    ? props.blok.buttons.map((button: any) => {
          return {
              label: button.label,
              style: button.style,
              url: button.link.cached_url,
              vEditable: button,
          } as ButtonType;
      })
    : [];

const animatedImageContent = computed<AnimatedImageContentType>(() => {
    return {
        image: {
            title: props.blok.image.title,
            alt: props.blok.image.alt,
            src: props.blok.image.filename,
        } as ImageType,
        secondaryImage: props.blok.secondary_image
            ? ({
                  title: props.blok.secondary_image.title,
                  alt: props.blok.secondary_image.alt,
                  src: props.blok.secondary_image.filename,
              } as ImageType)
            : null,
        calloutImage1: props.blok.callout_image_1
            ? ({
                  title: props.blok.callout_image_1.title,
                  alt: props.blok.callout_image_1.alt,
                  src: props.blok.callout_image_1.filename,
              } as ImageType)
            : null,
        calloutImage2: props.blok.callout_image_2
            ? ({
                  title: props.blok.callout_image_2.title,
                  alt: props.blok.callout_image_2.alt,
                  src: props.blok.callout_image_2.filename,
              } as ImageType)
            : null,
        calloutImage3: props.blok.callout_image_3
            ? ({
                  title: props.blok.callout_image_3.title,
                  alt: props.blok.callout_image_3.alt,
                  src: props.blok.callout_image_3.filename,
              } as ImageType)
            : null,
        calloutImage4: props.blok.callout_image_4
            ? ({
                  title: props.blok.callout_image_4.title,
                  alt: props.blok.callout_image_4.alt,
                  src: props.blok.callout_image_4.filename,
              } as ImageType)
            : null,
        heading: props.blok.heading,
        description: richTextToHtml(props.blok.description) as string,
        buttons,
        reverseLayout: props.blok.reverse_layout,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
        bottomAngle: props.blok.bottom_angle,
    };
});
</script>
